import "./heroLandingZone.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { convertDate, convertDuration } from "~tools/convert";
import { getChannelPicto } from "~tools/getChannelPics";
import { DOMHelper, View } from "~ui-lib";

export class HeroLandingZone extends View {
  cacheable = true;
  itemInfoBox: HTMLElement;
  itemTitleElt: HTMLElement;
  titleContainer: HTMLElement;
  itemSubTitleElt: HTMLElement;
  itemSummaryElt: HTMLElement;
  channel: HTMLElement;
  channelSeparator: HTMLElement;
  category: HTMLElement;
  duration: HTMLElement;
  diffusion: HTMLElement;
  collection: HTMLElement;
  details: Array<string>;
  tags: HTMLElement[];

  constructor(parent: HTMLElement, source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null));

    const itemDescElt = DOMHelper.createDivWithParent(parent, null, "itemDesc");
    this.itemTitleElt = DOMHelper.createDivWithParent(itemDescElt, null, "itemTitle");
    this.itemSubTitleElt = DOMHelper.createDivWithParent(itemDescElt, null, "itemSubTitle");
    this.itemInfoBox = DOMHelper.createDivWithParent(itemDescElt, "itemInfoBox");

    const channelContainer = DOMHelper.createDivWithParent(this.itemInfoBox, null, "channelContainer");
    this.channel = DOMHelper.createDivImg(channelContainer, null, "itemInfoChannel");
    this.channelSeparator = DOMHelper.createDivWithParent(channelContainer, null, "itemInfoChannelSeparator");
    this.category = DOMHelper.createDivWithParent(this.itemInfoBox, null, "category");
    this.duration = DOMHelper.createDivWithParent(this.itemInfoBox, null, "duration");
    this.diffusion = DOMHelper.createDivWithParent(this.itemInfoBox, null, "itemInfoDiffusion");
    this.collection = DOMHelper.createDivWithParent(this.itemInfoBox, null, "collection", "Collection");
    this.collection.style.visibility = "hidden";
    this.details = new Array<string>();
    this.tags = new Array<HTMLElement>();

    this.itemSummaryElt = DOMHelper.createDivWithParent(itemDescElt, null, "itemSummary");

    this.titleContainer = DOMHelper.createSpanWithParent(parent, null, "titleContainer");
    this.shouldTitleDisplaySponsorTag(source) &&
      DOMHelper.createSpanWithParent(this.titleContainer, null, "sponsoredTag swimlaneTitle", "sponsorisé");
    DOMHelper.createDivWithParent(this.titleContainer, null, "titleSwimlane", source.title);
  }

  resetItemDetails = async () => {
    this.itemTitleElt.innerHTML = "";
    this.itemSubTitleElt.innerHTML = "";
    this.itemSubTitleElt.style.display = "none";
    this.channel.style.background = "none";
    this.category.innerHTML = "";
    this.duration.innerHTML = "";
    this.collection.style.visibility = "hidden";
    this.channelSeparator.innerHTML = "";
    this.itemSummaryElt.innerHTML = "";
    this.details = new Array<string>();
    this.removeTags();
  };

  updateItem = async (model: any) => {
    await this.resetItemDetails();

    switch (model.type) {
      case "integrale":
      case "event":
        DOMHelper.addClass(this.itemTitleElt, "oneLiner");
        DOMHelper.removeClass(this.itemTitleElt, "twoLiner");
        break;
      default:
        DOMHelper.addClass(this.itemTitleElt, "twoLiner");
        DOMHelper.removeClass(this.itemTitleElt, "oneLiner");
        break;
    }

    if (model.extras?.event || model.extras?.program) {
      this.itemTitleElt.innerText = model.extras?.event
        ? model.extras.event.title
        : model.extras.program
        ? model.extras.program.title
        : "";
      this.itemSubTitleElt.innerText = model.extras?.event
        ? model.title
        : model.extras.program && model.extras.episode_title
        ? model.extras.episode_title
        : "";
      this.itemSubTitleElt.style.display = "-webkit-box";
    } else {
      this.itemTitleElt.innerText = model.title || "";
      this.itemSubTitleElt.innerText = "";
    }

    if (
      model.type === "integrale" ||
      model.type === "unitaire" ||
      model.type === "program" ||
      model.type === "extrait"
    ) {
      const channelPicto = getChannelPicto(model, true) || require("~images/channels/picto/france-tv.png");
      this.channel.style.background = `url(${channelPicto}) no-repeat`;
      this.channel.style.backgroundSize = "100%";
    } else if (model.type === "collection") {
      this.collection.style.visibility = "visible";
    }

    if (model.type === "program") {
      if (
        model.extras !== undefined &&
        typeof model.extras.number_of_episodes === "number" &&
        model.extras.number_of_episodes > 0
      ) {
        this.channelSeparator.innerHTML = "|";
        this.category.innerHTML =
          model.extras.number_of_episodes +
          " épisode" +
          (model.extras.number_of_episodes > 1 ? "s" : "") +
          " disponible" +
          (model.extras.number_of_episodes > 1 ? "s" : "");
        this.details.push(this.category.innerHTML);
      }
    }

    const _tags = Array<string>();
    if (model.media) {
      this.channelSeparator.innerHTML = "|";

      if (model.type === "integrale" || model.type === "unitaire") {
        const categoryTitle = model.extras?.category?.title;
        if (categoryTitle && categoryTitle.length) {
          this.category.innerHTML = categoryTitle.charAt(0).toUpperCase() + categoryTitle.slice(1);
          this.details.push(this.category.innerHTML);
        }
      }

      if (model.metadata?.duration) {
        this.category.innerHTML !== "" && DOMHelper.createSpanWithParent(this.category, null, "dotSeparator", "•");
        this.duration.innerHTML = convertDuration(model.metadata.duration);
        this.details.push(this.duration.innerHTML);
      }

      // Diffusion time TODO: Get new recurring diffusion times for integrale content
      if (model.type === "event") {
        if (model.metadata.extras && model.metadata.extras.is_live) {
          const broadcastInfo =
            model.media && model.media.broadcast && model.media.broadcast.start_date && model.media.broadcast.end_date
              ? model.media.broadcast
              : null;

          if (broadcastInfo) {
            DOMHelper.createSpanWithParent(this.duration, null, "dotSeparator", "•");
            const _formatTime = function (hour: number): string {
              return hour < 10 ? "0" + hour : String(hour);
            };
            const startDate = broadcastInfo.start_date;
            const endDate = broadcastInfo.end_date;
            const startDateString =
              "de " + _formatTime(startDate.getHours()) + "h" + _formatTime(startDate.getMinutes());
            const endDateString = " à " + _formatTime(endDate.getHours()) + "h" + _formatTime(endDate.getMinutes());
            this.diffusion.innerText = startDateString + endDateString;
            this.details.push(this.diffusion.innerText);
          }
        } else {
          if (model.media?.broadcast?.start_date && !model.metadata?.extras?.is_live) {
            DOMHelper.createSpanWithParent(this.duration, null, "dotSeparator", "•");
            this.diffusion.innerHTML = "diffusé le " + convertDate(model.media.broadcast.start_date);
            this.details.push(this.diffusion.innerHTML);
          }
        }
      }

      // Tags
      if (model.metadata && model.metadata.extras && model.metadata.extras.is_multi_lingual) {
        _tags.push("VM");
      }
      if (
        model.metadata &&
        model.metadata.rating &&
        model.metadata.rating.code &&
        model.metadata.rating.code !== "TP"
      ) {
        _tags.push(model.metadata.rating.code);
      }
      if (model.metadata && model.metadata.extras) {
        if (model.metadata.extras.is_audio_descripted) {
          _tags.push("AD");
        }
        if (model.metadata.extras.is_subtitled) {
          _tags.push("HI");
        }
      }
    }
    model.sponsored && _tags.push("sponsored");

    this.tags?.length && this.removeTags();
    if (_tags.length > 0) {
      if (this.details.length === 0 && this.channel.style.background !== "none") {
        this.channelSeparator.innerHTML = "|";
      } else {
        const separator = DOMHelper.createSpanWithParent(this.duration, null, "dotSeparator", "•");
        separator.style.paddingRight = `13px`;
      }
    }
    _tags.forEach(tag => {
      switch (tag) {
        case "VM":
          this.createIconTag(require("~images/pictos_options/vm.png"));
          break;
        case "10":
        case "12":
        case "16":
        case "18":
          this.createIconTag(model.metadata.rating.picto(model.metadata.rating.code));
          break;
        case "AD":
          this.createIconTag(require("~images/pictos_options/ad.png"));
          break;
        case "HI":
          this.createIconTag(require("~images/pictos_options/hearing_impaired.png"));
          break;
        case "sponsored":
          this.tags?.push(
            DOMHelper.createSpanWithParent(this.itemInfoBox, null, "sponsoredTag heroZoneTag", "sponsorisé")
          );
          break;
        default:
          {
            model.metadata.rating.title && this.createTextTag(model.metadata.rating.title);
          }
          break;
      }
    });

    if (model instanceof Program) {
      DOMHelper.addClass(this.itemSummaryElt, "program");
    } else {
      DOMHelper.removeClass(this.itemSummaryElt, "program");
    }

    this.itemSummaryElt.innerHTML = model.summary ? model.summary : "";
  };

  createIconTag = (imgUrl: string) => {
    if (imgUrl) {
      const contentBoxPicto = DOMHelper.createDivWithParent(this.itemInfoBox, null, "contentBoxPicto");
      const picto = DOMHelper.createDivWithParent(contentBoxPicto, null, "itemInfoPictoBox");
      const imageItem = DOMHelper.createDivImg(picto, null, "itemInfoPictoIcon");
      imageItem.style.background = `url(${imgUrl})`;
      imageItem.style.backgroundSize = `22px 22px`;
      imageItem.style.backgroundRepeat = "no-repeat";
      imageItem.style.backgroundPosition = "center";
      this.tags?.push(contentBoxPicto);
    }
  };

  createTextTag = (text: string) => {
    if (text.length) {
      const contentBoxPicto = DOMHelper.createDivWithParent(this.itemInfoBox, null, "contentBoxPicto");
      const picto = DOMHelper.createDivWithParent(contentBoxPicto, null, "itemInfoPictoBox");
      DOMHelper.createDivWithParent(picto, null, "itemInfoPictoText", text.toLowerCase());
      this.tags?.push(contentBoxPicto);
    }
  };

  shouldTitleDisplaySponsorTag = (source: ItemCollection): boolean => {
    if (source.sponsored) {
      return true;
    } else {
      // Check that all tiles in slider are sponsored
      for (const item in source.items) if (!source.items[item].sponsored) return false;
    }
    return true;
  };

  removeTags = () => {
    if (this.tags !== undefined) {
      for (const tag of this.tags) {
        tag?.remove();
      }
    }
    this.tags = new Array<HTMLElement>();
  };
}
