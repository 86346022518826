import "./playerMagnetoPage.scss";

import { z } from "zod";

import { DOMHelper, IPage, Keys, platform, PlatformType, View } from "../../../bud-lite-tv/src/lib";
import { Config } from "../../config";
import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { ItemCollection } from "../../models/itemCollection";
import { PlayableItem } from "../../models/playableItem";
import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { getOriginPageParams } from "../../tools/analytics/piano";
import { ApiOrange } from "../../tools/apiOrange/apiOrange";
import { getOrangeProviderIdFromBroadcastChannel } from "../../tools/apiOrange/channelOrangeProviderIdMap";
import { OrangeDeviceInfo, OrangeEnvironmentInfo } from "../../tools/apiOrange/orangeDeviceInfo";
import { orangeErrorBuild, orangeErrorBuildMagnetoLoading } from "../../tools/apiOrange/orangeError";
import { PlayHistoryHelper } from "../../tools/playerHistoryHelper";

const _playerVersionElement = DOMHelper.createDivWithParent(
  document.getElementById("mainContent"),
  "PlayerMagnetoVersion"
);

let _magnetoVersion = "";

export const magnetoVersion = () => {
  return _magnetoVersion;
};

const MagnetoError = z.object({
  code: z.number().nullish(),
  message: z.string().nullish(),
  type: z.string().nullish(),
  orangeErrorCode: z.number().nullish(),
  orangeUserCode: z.string().nullish(),
  orangeMessage: z.string().nullish(),
});

interface PlayerMagnetoOptions {
  publicId?: string;
  autostart?: boolean;
  platform?: string;
  videoProductId: string; // [SMARTTV-1225][Orange OTT] Ajout d'une option magneto videoProductId au load d'une video
  consent?: {
    ad?: string;
    estat?: "optin" | "optout" | "exempt";
    npaw?: "optin" | "exempted";
    nielsen?: "consented" | "undefined";
    freewheel?: boolean;
    adUserId?: string;
    pubUserId?: string;
    recoUserId?: string;
  };
  env?: {
    device?: string;
    firmware?: string;
    connection_type?: string;
    app_version?: string;
    showAd?: boolean;
  };
  showAd?: boolean;
  preroll?: boolean;
  startTimecode?: number;
  tracking?: {
    page?: string;
    pageType?: string;
    pageProvenance?: string;
    pageProvenanceType?: string;
    zoneProvenance?: string;
    positionVignette?: string | number;
    playProvenance?: PlayProvenance;
  };
  next?: boolean;
  diffusion?: {
    mode?: "tunnel" | "tunnel_first";
    position?: string | number;
    length?: string | number;
  };
  comingNext?: {
    program?: string;
    title?: string;
    preTitle?: string;
  };
  logo?: string;
  debug?: boolean;
}

interface PlayerInitData {
  src: string;
  config: PlayerMagnetoOptions;
}

type PlayProvenanceTunnel = "tunnel_auto" | "tunnel_next" | "tunnel_comingnext";
type PlayProvenance = PlayProvenanceTunnel | "recommendation" | "rejouer" | "reessayer" | "zapette" | "relancer";

type PlayerMagnetoEvent =
  | "error"
  | "next"
  | "settingsOpened"
  | "settingsClosed"
  | "trackListOpened"
  | "trackListClosed"
  | "tunnelOpened"
  | "tunnelClosed"
  | "spritesheetsOpened"
  | "spritesheetsClosed"
  | "playerClose";
// | "new_player"
// | "pause"
// | "canplay"
// | "canplaythrough"
// | "waiting"
// | "zappingOpened"
// | "zappingClicked"
// | "userVolumeChange"
// | "userPlaybackRateChange"
// | "userQualityChanged"
// | "userTextTrackChanged"
// | "userTunnelActivated"
// | "blur"
// | "comingNextDisplayed"
// | "enterpictureinpicture"
// | "leavepictureinpicture"
// | "new_player"
// | "new_video"
// | "pause "
// | "play"
// | "playing"
// | "renderer_ready"
// | "seeked"
// | "seeking"
// | "stalled"
// | "stopped"
// | "timeshiftingBackToLiveRequested"
// | "timeupdate"
// | "userAudioTrackChanged"
// | "userMute"
// | "userVolumeChanged"
// | "video_start"
// | "watchedTimeReached"
// | "zappingClicked"
// | "zappingOpened";

interface PlayerMagnetoType {
  load: (video: PlayerInitData) => void;
  play: () => void;
  pause: () => void;
  // stop: () => void;
  // seek: (position: number) => void;
  // forward: () => void;
  // rewind: () => void;
  // next: () => void;
  // mute: (value: boolean) => void;
  // volume: (value: number) => void;
  // fullscreen: (active: boolean) => void;
  on: (eventName: PlayerMagnetoEvent, callback: (eventName: string, payload: unknown) => void) => void;
  off: (eventName: PlayerMagnetoEvent, callback: (eventName: string, payload: unknown) => void) => void;
  getCurrentTime: () => number;
  // getDuration: () => number;
  // getCurrentProgress: () => number;
  // getPlayerContainer: () => HTMLElement;
  // getLayer: () => HTMLElement;
  // setReco: (recommendations: any[]) => void;
  dispose: (emptyContainer: boolean) => void;
  // isAdServerReachable: () => boolean;
  toJSON: () => {
    version: string;
    name: string;
  };
}

type Tunnel = {
  currentPosition: number;
  readonly contents: PlayableItem[];
  nextItem: PlayableItem | undefined;
  startTimecode: number;
};

export class PlayerMagnetoPage extends View implements IPage {
  private _Magnetoscope = (window as any).magnetoscope;
  private _player?: PlayerMagnetoType | undefined;
  private _currentItem: PlayableItem;
  private readonly _progressInSec: number;
  private _indexInSlider: number | undefined;
  private _isPlaying = true;
  private _isLive: boolean;
  private _handleBackButton = true;

  constructor(item: PlayableItem, index?: number) {
    super(DOMHelper.createDivWithParent(null, "PlayerMagnetoPage", "playerMagnetoPage"));
    this._currentItem = item;
    this._isLive = item.metadata.extras?.is_live ? true : false;
    this._indexInSlider = index != undefined ? index + 1 : undefined;

    // manage progress
    const user = Plugin.getInstance().user;
    // don't fetch the progress if it is a live or user is not logged in
    if (this._isLive === false && user.isActive()) {
      const duration = item.metadata.duration;
      this._progressInSec = PlayHistoryHelper.getCurrentOffset(item) ?? 0;
      this._progressInSec = Math.max(0, Math.min(duration, this._progressInSec));
      if (this._progressInSec > 0.96 * duration) {
        this._progressInSec = 0;
      }
    } else {
      this._progressInSec = 0;
    }
  }

  private _getConfigPublicId = () => {
    const userOrangeConsents = Plugin.getInstance().user.datas?.consents;
    const statsConsent = userOrangeConsents?.find(userConsent => userConsent.id === "STATS");
    return statsConsent?.enabled === true ? statsConsent.userId : undefined;
  };

  private _getConfigConsent = () => {
    const userOrangeConsents = Plugin.getInstance().user.datas?.consents;

    const statsConsent = userOrangeConsents?.find(userConsent => userConsent.id === "STATS");
    const pubConsent = userOrangeConsents?.find(userConsent => userConsent.id === "PUB");
    const recosConsent = userOrangeConsents?.find(userConsent => userConsent.id === "RECOS");

    return {
      estat: statsConsent?.enabled ? "optin" : "exempt",
      npaw: statsConsent?.enabled ? "optin" : "exempted",
      nielsen: statsConsent?.enabled ? "consented" : "undefined",
      freewheel: pubConsent ? pubConsent?.enabled : false,
      adUserId: pubConsent
        ? pubConsent?.enabled && pubConsent.partnerUserId
          ? pubConsent.partnerUserId
          : undefined
        : undefined,
      pubUserId: pubConsent ? (pubConsent?.enabled && pubConsent.userId ? pubConsent.userId : undefined) : undefined,
      recoUserId: recosConsent
        ? recosConsent?.enabled && recosConsent.userId
          ? recosConsent.userId
          : undefined
        : undefined,
    } as const;
  };

  private _getConfigEnv = () => {
    console.log("<<< config env");
    return {
      device: OrangeDeviceInfo.modelName,
      firmware: OrangeDeviceInfo.version,
      connection_type: OrangeEnvironmentInfo(),
      app_version: __APP_VERSION__,
    };
  };

  private _getConfigPlatform = () => {
    return "orangeott";
  };

  private _onError = (eventName: string, payload: unknown) => {
    Log.player.error("[MAGNETO] error", payload);
    const magnetoErrorParseResult = MagnetoError.safeParse(payload);
    if (magnetoErrorParseResult.success) {
      const data = magnetoErrorParseResult.data;
      const error = orangeErrorBuild({
        errorCode: "error" + (data.code ?? "0000"),
        orangeErrorCode: data.orangeErrorCode ?? 99,
        popUp: {
          code: (data.orangeUserCode ?? "H09") + "-FTV", // adding suffix partnerId because magneto return only Hxx SMARTTV-1253
          message: data.orangeMessage ?? "Accès au service momentanément indisponible",
        },
        message: data.type ?? undefined,
        description: data.message ?? undefined,
      });
      ApiOrange.fetchErrorLogsCollector(error);
    } else {
      Log.player.error("[MAGNETO] parsing magnetoError error", magnetoErrorParseResult.error);
    }
  };

  private _onPlayerClose = () => {
    Log.player.info("[MAGNETO] onPlayerClose");
    this._saveProgress();
    if (platform.type === PlatformType.orange && Config.openedPlayerFromDeeplink) {
      platform.exit();
    } else {
      navigationStack.removePage(this);
    }
  };

  init = () => {
    Log.player.log("[MAGNETO] init");
    try {
      const initMagnetoOptions: {
        debug: boolean;
        platform: string;
        webservices?: { mediation?: { gateway?: string } };
      } = {
        debug: true,
        platform: this._getConfigPlatform(),
      };
      if (__BACKEND_TARGET__ === "pre") {
        initMagnetoOptions.webservices = {
          mediation: {
            gateway: "https://proxy-mediation.yatta.francetv.fr/preprod/v2/gateway-player/videos/",
          },
        };
      }

      this._player = new this._Magnetoscope(this.rootElement, initMagnetoOptions) as PlayerMagnetoType;
      _magnetoVersion = this._player?.toJSON?.()?.version ?? "";
      if (__IS_RELEASE__ === false) {
        _playerVersionElement.innerText = _magnetoVersion;
      }
    } catch (e: unknown) {
      Log.player.log("[MAGNETO] init, cannot create new magneto player.");
      ApiOrange.fetchErrorLogsCollector(orangeErrorBuildMagnetoLoading());
      // TODO: maybe display a popup and remove the page
      return;
    }

    this._player.on("error", this._onError);

    this._player.on("playerClose", this._onPlayerClose);

    // Setting listeners
    const openedEvents: PlayerMagnetoEvent[] = [
      "trackListOpened",
      "tunnelOpened",
      "spritesheetsOpened",
      "settingsOpened",
    ];
    const closedEvents: PlayerMagnetoEvent[] = [
      "trackListClosed",
      "tunnelClosed",
      "spritesheetsClosed",
      "settingsClosed",
    ];

    for (const event of openedEvents) {
      this._player.on(event, () => {
        this._setHandleBackButton(false);
      });
    }

    for (const event of closedEvents) {
      this._player.on(event, () => {
        this._setHandleBackButton(true);
      });
    }

    if (this._isLive === false) {
      Plugin.getInstance()
        .fetchNextEpisodes(this._currentItem)
        .toPromise()
        .then((value: unknown[]) => {
          const nextEpisodesItemCollection = value[0];
          if (nextEpisodesItemCollection instanceof ItemCollection && nextEpisodesItemCollection.items.length > 0) {
            // ajout dde l'item au début du tunnel
            nextEpisodesItemCollection.items.unshift(this._currentItem);
            const tunnel: Tunnel = {
              currentPosition: 0,
              contents: nextEpisodesItemCollection.items,
              nextItem: nextEpisodesItemCollection.items[1],
              startTimecode: this._progressInSec ?? 0,
            };
            this._player?.on("next", (eventName, payload) => {
              const playProvenance = (() => {
                if (payload === "button") {
                  return "tunnel_next";
                } else if (payload === "comingNext") {
                  return "tunnel_comingnext";
                } else {
                  return "tunnel_auto";
                }
              })();

              this._saveProgress();
              if (tunnel.currentPosition + 1 < tunnel.contents.length) {
                tunnel.currentPosition++;
                this._currentItem = tunnel.contents[tunnel.currentPosition];
                if (tunnel.currentPosition + 1 < tunnel.contents.length) {
                  tunnel.nextItem = tunnel.contents[tunnel.currentPosition + 1];
                  tunnel.startTimecode = 0;
                } else {
                  tunnel.nextItem = undefined;
                }
              }
              this._loadVideo(playProvenance, tunnel);
            });

            // loading first video
            this._loadVideo(undefined, tunnel);
          } else {
            // tunnel is empty
            this._loadVideo(undefined, undefined);
          }
        })
        .catch((error: unknown) => {
          // err loading initial video without tunnel
          Log.player.error("fetchNextEpisodes failed", error);
          this._loadVideo(undefined, undefined);
        });
    } else {
      // currentItem is a live, loading without tunnel
      this._loadVideo(undefined, undefined);
    }
  };

  private _setHandleBackButton(handleBackButton: boolean) {
    this._handleBackButton = handleBackButton;
  }

  private _loadVideo = (playProvenance: PlayProvenance | undefined, tunnel: Tunnel | undefined) => {
    sendOrangePageViewEvent({
      pTitle: "Player",
      pSubtitle: this._currentItem.title,
      providerId: getOrangeProviderIdFromBroadcastChannel(this._currentItem),
    });

    if (this._player === undefined) {
      Log.app.error("[MAGNETO] player is undefined, init was not called or page is released!");
      return;
    }
    Log.player.log("[MAGNETO] loading video...", this._currentItem.id);

    const originPageParams = getOriginPageParams();

    // if the playableItem is a "live" and not "externe", it means that is a "live channel", the si_id have to be taken from extras.channel.si_id
    let si_id = undefined;
    if (
      this._currentItem.metadata?.extras?.is_live &&
      this._currentItem.media?.broadcast?.extras?.broadcast_channel &&
      this._currentItem.media.broadcast.extras.broadcast_channel !== "externe"
    ) {
      si_id = this._currentItem.extras?.channel?.extras?.si_id;
    } else {
      si_id = this._currentItem.extras?.si_id;
    }

    if (typeof si_id !== "string") {
      Log.app.error("[MAGNETO] si_id is not a string", this._currentItem);
      return;
    }

    const startTimecode = tunnel === undefined ? this._progressInSec : tunnel.startTimecode;
    const videoOptions: PlayerInitData = {
      src: si_id,
      config: {
        autostart: true,
        publicId: this._getConfigPublicId(),
        showAd: !this._currentItem.extras.ads_blocked,
        startTimecode,
        platform: this._getConfigPlatform(),
        tracking: {
          // TODO: check what to do
          page: "player",
          pageType: "player",
          pageProvenance: originPageParams.page ?? undefined,
          pageProvenanceType: originPageParams.pageType ?? undefined,
          // zoneProvenance: this._item?.itemCollection?.type ?? undefined,
          // positionVignette: this._indexInSlider,
          playProvenance,
        },
        consent: this._getConfigConsent(),
        env: this._getConfigEnv(),
        debug: true,
        videoProductId: this._currentItem.id,
      },
    };

    if (!this._currentItem.extras.ads_blocked && startTimecode > 0) {
      //La vidéo est pubbable et c’est une reprise de lecture
      videoOptions.config.preroll = false;
    }

    if (this._isLive) {
      const channelLogo: string | undefined = this._currentItem.extras?.channel?.getLogoImgUrl();
      if (channelLogo !== undefined) {
        videoOptions.config.logo = channelLogo;
      }
    }

    if (tunnel !== undefined) {
      videoOptions.config.diffusion = {
        mode: tunnel.currentPosition === 0 ? "tunnel_first" : "tunnel",
        position: tunnel.currentPosition + 1,
        length: tunnel.contents.length,
      };
      if (tunnel.nextItem !== undefined) {
        videoOptions.config.next = true;
        videoOptions.config.comingNext = {
          program: tunnel.nextItem.extras?.program?.title ?? "", // TODO: verfiy how to get the title when item is from an event and don't have program
          title: tunnel.nextItem.extras?.episode_title ?? "",
          preTitle: "",
        };
      } else {
        videoOptions.config.next = false;
      }
    } else {
      // no tunnel
      videoOptions.config.next = false;
    }

    Log.app.error(JSON.stringify(videoOptions.config));

    this._player.load(videoOptions);
  };

  onRelease = () => {
    Log.player.log("[MAGNETO] Destroying player...");
    this._player?.dispose(false);
    this._player = undefined;
  };

  private _saveProgress = () => {
    const user = Plugin.getInstance().user;
    if (this._isLive === false && user.isActive() && this._player !== undefined) {
      const currenTime = this._player.getCurrentTime();
      Plugin.getInstance()
        .putHits(user, this._currentItem, currenTime)
        .subscribe(
          value => {
            // Here use it to get content searched
            Log.api.log("[putHits] next !", value);
          },
          error => {
            // Here use it to trigger and display an error
            Log.api.log("[putHits] Error !", error);
          },
          () => {
            Log.api.log("[putHits] Complete !");
          }
        );

      ApiOrange.fetchReplayPosition(
        this._currentItem.id,
        currenTime,
        getOrangeProviderIdFromBroadcastChannel(this._currentItem)
      );
      PlayHistoryHelper.updateOffset(this._currentItem, currenTime);
    }
  };

  // These key handlers are here for testing. Magneto will implement it on its side.
  onNav = (key: Keys): boolean => {
    Log.player.log(key);
    switch (key) {
      case Keys.play:
        this._player?.play();
        return true;
      case Keys.playPause:
        this._isPlaying ? this._player?.pause() : this._player?.play();
        this._isPlaying = !this._isPlaying;
        return true;
      case Keys.pause:
        this._player?.pause();
        return true;
      case Keys.stop:
        this._onPlayerClose();
        return true;
      case Keys.back:
        if (this._handleBackButton) {
          // saveProgress should not be called in onRelease, because onRelease will be called after the onShown of the videoTile
          // the progressBar of the videoTile will have then the correct value
          this._saveProgress();
          // for orangge ott, app should exit if player was opened from a deeplink
          if (platform.type === PlatformType.orange && Config.openedPlayerFromDeeplink) {
            platform.exit();
            return true;
          }
          // return false, navigationStack will remove the page
          return false;
        } else {
          // return true to stop propagation of the event, backButton is handled by magnetoPlayer
          return true;
        }
    }
    return false;
  };
}
