"use strict";
// this is the first thing to call in main. It defines the polyfills depending on the platform type,
// and does platform specific work (key registering for example)
import { loadJS } from "~tools/tools";

require("~ui-lib/platform");
// the app needs string.normalize for analytics
require("unorm");

// then we need log to be defined ASAP
require("./log");

import "./main.scss";
import "@procot/webostv/webOSTV-dev";

import { logKeyHandler } from "@dotscreen/log-tv";

import { Storage } from "~libs/storage";
import { createNavigationStack, Keys, mapKeyboardEvent, platform, PlatformType } from "~ui-lib";

// it's *CRITICAL* this is created before any other list, as it's defining if a list supports mouse or not
// the rootMenu uses list, and if it's imported before this stack is created it won't be created as mouse-compatible
export const navigationStack = createNavigationStack({
  rootElement: document.getElementById("navigationStack")!,
  mouseSupport: true,
  wheelThrottle: platform.type == PlatformType.other ? 500 : undefined,
  onExit: () => {
    console.warn("exit! show menu first?");
  },
});

import { z } from "zod";

import { PopupPage } from "~pages/popup/popupPage";
import { OrangeDeviceInfo } from "~tools/apiOrange/orangeDeviceInfo";
import { orangeErrorBuild } from "~tools/apiOrange/orangeError";

import { Config } from "./config";
import { Plugin } from "./datas/plugin";
import { Category } from "./models/category";
import { Channel } from "./models/channel";
import { Collection } from "./models/collection";
import { Event } from "./models/event";
import { Extrait } from "./models/extrait";
import { Flux } from "./models/flux";
import { Integrale } from "./models/integrale";
import { Media } from "./models/media";
import { Metadata } from "./models/metadata";
import { Program } from "./models/program";
import { Unit } from "./models/unit";
import { USER_ID_STORAGE_KEY } from "./models/user";
import { CategoryDetailTab } from "./pages/category/categoryDetailTab";
import { ChannelTab } from "./pages/channel/channelTab";
import { ConsentManagementTab } from "./pages/cmp/consentManagement/consentManagementPage";
import { CollectionTab } from "./pages/collection/collectionTab";
import { EventTab } from "./pages/event/eventTab";
import { HomeTab } from "./pages/home/homeTab";
import { LoaderStaticPageHelper } from "./pages/loaderStatic/loaderStaticPage";
import { OkooTab } from "./pages/okoo/okooTab";
import { AgePage } from "./pages/okooAgePage/agePage";
import { PopupErrorAuth } from "./pages/popup/popupErrorAuth";
import { ProgramTab } from "./pages/program/programTab";
import { pushConnexionPage, pushPlayerPage, pushTabWithMenu } from "./pages/rootPage";
import { UnitTab } from "./pages/unit/unitTab";
import { sendOrangeEvent } from "./tools/analytics/orangeStats";
import { initializePianoAnalytics } from "./tools/analytics/piano";
import { ApiOrange } from "./tools/apiOrange/apiOrange";
import { DEFAULT_ORANGE_PROVIDER_ID } from "./tools/apiOrange/orangeProviderId";
import { UserData } from "./tools/apiOrange/userData";
import { Didomi } from "./tools/cmp/didomi";
import { startMultitaskingManager } from "./tools/multitasking";
import { MainMenuItemSlug } from "./views/mainMenu/itemMenu";

LoaderStaticPageHelper.init(document.getElementById("navigationStack"));

// the app entry point. Nothing should be done / created / instantiated before that is called
function startApp(): void {
  if (Config.enabledFeatures.lowDevice) {
    document.body.classList.add("lowDevice");
  }

  document.addEventListener(
    "keydown",
    ev => {
      ev.preventDefault(); // TODO: ORANGE : called to prevent the default behavior of the key back on Orange box that quits the app when pressed, may be preventDefault() should not be called for every keys.
      const key = mapKeyboardEvent(ev);
      // if log handles key we don't want the app to process the key
      if (logKeyHandler(key, "keydown")) return;
      // don't allow navigation if the loading screen is displayed
      if (LoaderStaticPageHelper.isDisplayed() && ![Keys.back, Keys.exit].includes(key)) return;
      navigationStack.keyHandler(key);
    },
    false
  );

  Log.app.warn("platform.type=" + platform.type);

  const _onLoadPlayerError = () => {
    const error = orangeErrorBuild({
      errorCode: "H03-FTV",
      orangeErrorCode: 99,
      message: "PLAYER_UNAVAILABLE",
      description:
        "Problème lié à la lecture des contenus dans le service partenaire lors de l'initialisation du player",
      popUp: {
        code: "H03-FTV",
        message:
          "Accès au programme momentanément indisponible.\n" +
          " \n" +
          " Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner.\n" +
          " Il est possible que ce programme ne soit plus disponible.\n" +
          " \n" +
          " Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.\n" +
          " \n" +
          " Code erreur : H03-FTV ",
      },
    });
    ApiOrange.fetchErrorLogsCollector(error);
  };

  /**
   * Samsung: Implementing Public Preview Deep Links
   */
  if (platform.type == PlatformType.tizen) {
    /**
     * To receive "action_data" information when the application is already running,
     * add an event listener for the appcontrol event to the onload property
     */
    Log.app.log("[deepLinkTizen appcontrol] put listener here !! ");

    window.addEventListener("appcontrol", () => {
      deepLinkTizen();
    });
  } else if (platform.type == PlatformType.orange) {
    if (OrangeDeviceInfo.isNewBox()) {
      if (!window.Intl) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Intl = {};
      }
      if (!Intl.Collator) {
        Log.app.warn("Intl.Collator doesn't exist");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Intl.Collator = function () {};
        Intl.Collator.prototype = {
          compare: function (a: string, b: string) {
            if (a === b) {
              return 0;
            }
            return a < b ? -1 : 1;
          },
        };
      }

      loadJS("./player/dashjs-player_" + __APP_HASH__ + ".js")
        .then(() => {
          if (typeof window.dashjs === "undefined") {
            throw new Error("DashJs is undefined");
          } else {
            Log.app.warn("Succeeded to load DashJS");
          }
        })
        .catch(e => {
          Log.app.error("Failed to load DashJS:", e);
          _onLoadPlayerError();
        });
    } else {
      const isPreprod = __BACKEND_TARGET__ === "pre" || __BACKEND_TARGET__ === "dev";
      //prod url
      let magnetoscopeUrl =
        "https://proxy-mediation.yatta.francetv.fr/prod/v2/static/magnetoscope-tv/main.magnetoscope.js";
      if (isPreprod) {
        //preprod url
        magnetoscopeUrl =
          "https://proxy-mediation.yatta.francetv.fr/preprod/v2/static/magnetoscope-tv/main.magnetoscope.js";
      }

      loadJS(magnetoscopeUrl)
        .then(() => {
          if (typeof (window as any).magnetoscope === "undefined") {
            throw new Error("Magnetoscope is undefined");
          }
        })
        .catch(error => {
          Log.app.error("Failed to load magnetoscope:", error);
          _onLoadPlayerError();
        });
    }
  }

  if (!__IS_RELEASE__) {
    // debug version
    document.querySelector("#versionApp")!.innerHTML = `${__APP_VERSION__}-${__APP_HASH__}-${
      __IS_PRODUCTION__ ? "prod" : "dev"
    }`;
    // // debug counter
    // showDebugStats("debugStats");
  }

  startMultitaskingManager();

  /**
   * Logged in user with valid consent -> HomeTab
   * Logged in user without consent -> ConsentManagementTab -> Home Page
   * User with valid consent -> Log in Page
   * User without consent -> ConsentManagementTab -> Log in Page
   *
   *
   * should be:
   * Log in user / user without consent -> ConsentManagementTab -> HomeTab / Log In Page
   * Log in user / user with consent -> HomeTab / Log In Page
   *
   * Meaning that we should:
   * 1. Log in user
   * 2. Check Didomi
   * 3. Redirect to valid page
   */

  void (async () => {
    // 1. Reconnect user if needed
    if (Config.enabledFeatures.login) {
      if (Storage.getItem(USER_ID_STORAGE_KEY)) {
        try {
          //await Plugin.getInstance().fetchReconnect().toPromise();
        } catch (error) {
          Log.app.error(error);

          // Failed to reconnect user, disconnecting user
          Plugin.getInstance().user.deconnect();
        }
      }
    }

    const fetchUserDataWithErrorPopupExecutor = (resolve: (value: UserData | "dégradé") => void) => {
      const showErrorPopup = (withRetryButton: boolean) => {
        const retryButton = withRetryButton
          ? {
              label: "Réessayer",
              action: () => {
                fetchUserDataWithErrorPopupExecutor(resolve);
              },
            }
          : undefined;
        const exitButton = {
          label: "Quitter",
          action: () => {
            platform.exit();
          },
        };
        const fakeAuthOrangeAllowed = !__IS_PRODUCTION__ || window.location.href.includes("dotscreen.com");
        const fakeAuthButton = fakeAuthOrangeAllowed
          ? {
              label: "FakeAuth",
              action: () => {
                const fakeUser: UserData = {
                  hasAccess: true,
                  userId: "123transactionFakeAuth",
                  rlecontents: [],
                  consents: [
                    { id: "STATS", enabled: true, userId: "123statsFakeAuth" },
                    { id: "RECOS", enabled: true, userId: "123recoFakeAuth" },
                    {
                      id: "PUB",
                      enabled: true,
                      userId: "123aduserIdFakeAuth",
                      partnerUserId: "123partneruserIdFakeAuth",
                    },
                  ],
                };
                resolve(fakeUser);
              },
            }
          : undefined;
        navigationStack.pushPage(
          new PopupErrorAuth({
            button1: retryButton,
            button2: exitButton,
            button3: fakeAuthButton,
          })
        );
      };

      ApiOrange.fetchUserData()
        .then(value => {
          if (value.consents.length <= 0) {
            Log.app.error("Error fetchUserData return no consents", value);
            resolve(value);
          } else {
            resolve(value);
          }
        })
        .catch((e: unknown) => {
          const parseError = z.object({ response: z.object({ status: z.number() }) }).safeParse(e);
          if (parseError.success && [400, 404, 500, 503].includes(parseError.data.response?.status)) {
            resolve("dégradé");
          } else {
            Log.app.error(e);
            showErrorPopup(true);
          }
        });
    };

    const orangeUserDataPromise = new Promise<UserData | "dégradé">(fetchUserDataWithErrorPopupExecutor);
    const orangeUserData = await orangeUserDataPromise;
    await Plugin.getInstance().loginOrange(orangeUserData);

    // 2. Initialize didomi for current user
    if (Config.enabledFeatures.cmp) {
      await Didomi.getUserConsent();
    }

    // 3. Initialize PA
    initializePianoAnalytics();

    // 4. Check if user should be prompted CMP
    if (Config.enabledFeatures.cmp && Didomi.shouldUserBePromptedCmp()) {
      navigationStack.pushPage(new ConsentManagementTab());
    } else {
      if (Config.enabledFeatures.login) {
        const user = Plugin.getInstance().user;
        if (user.isActive()) {
          pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
        } else {
          pushConnexionPage();
        }
      } else {
        pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
      }
    }
  })();
}

export function checkDeepLink() {
  switch (platform.type) {
    case PlatformType.tizen:
      // the deeplink value is supposed to be handled with Tizen listener where we call deepLinkTizen()
      deepLinkTizen();
      break;
    case PlatformType.webos:
      deepLinkLG();
      break;
    case PlatformType.orange:
      deepLinkOrange();
      break;
    default:
      loadDeepLink();
      break;
  }
}

/**
 * Example:
 * OPEN PAGE
 * #page/program/france-2_l-amie-prodigieuse => open a page program with ID = france-2_l-amie-prodigieuse
 * #page/program/spectacles-et-culture_emissions-culturelles_culturebox-l-emission
 * #page/categorie/series-et-fictions
 * #page/collection/1801255
 * #page/collection/2715013
 * #page/collection/2223891
 * #page/event/sport_les-jeux-olympiques => open a page unit with ID = sport_les-jeux-olympiques
 * #page/channel/france-2
 * #page/channel/slash
 * #page/unit/2587231 => open a page unit with ID = 2587231
 * #page/unit/2702889
 * #page/unit/2702893
 *
 * OPEN PLAYER
 * #player/unit/2587231 => open a page unit with ID = 2587231
 */

function loadDeepLink(deeplink?: string) {
  Log.app.log("[deepLinkApp] deeplink?: " + deeplink);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let deepLinkPathName: string | any[] = [];
  if (deeplink) {
    deeplink = deeplink.replace("#", "");
    deepLinkPathName = deeplink.split("/");
  } else {
    deepLinkPathName = location.hash.substr(1, location.hash.length).split("/");
    history.replaceState("", "FranceTv", `${location.pathname}`);
  }

  if (deepLinkPathName.length > 1) {
    const page = deepLinkPathName[0];
    const type = deepLinkPathName[1];
    const url_id = deepLinkPathName[2];

    // open detail page (menu on left)
    if (page == "page") {
      switch (type) {
        case "program":
          pushTabWithMenu(
            new ProgramTab(new Program("", "", "", "", [], { program_path: url_id }, false)),
            "ProgramPage"
          );
          break;
        case "collection":
          pushTabWithMenu(new CollectionTab(new Collection(url_id, "", "", "", [], {}, false)), "CollectionPage");
          break;
        case "categorie":
          pushTabWithMenu(
            new CategoryDetailTab(new Category("", "", "", "", [], { url_complete: url_id })),
            "CategoryPage"
          );
          break;
        case "unit":
        case "extrait":
        case "integrale":
          pushTabWithMenu(
            new UnitTab(
              new Unit(
                url_id,
                "",
                "",
                "",
                [],
                new Metadata({ is_live: false }, 0),
                {},
                new Media({}, null, null, null),
                true,
                false
              )
            ),
            "UnitPage"
          );
          break;
        case "event":
          pushTabWithMenu(new EventTab(new Event("", "", "", "", [], { url_complete: url_id }, false)), "EventPage");
          break;
        case "channel":
          if (url_id === "okoo") {
            pushTabWithMenu(
              new AgePage(true, () => {
                pushTabWithMenu(new OkooTab(), "OkooPage");
              }),
              "OkooAgePage",
              MainMenuItemSlug.kids
            );
          } else {
            pushTabWithMenu(new ChannelTab(new Channel("", "", "", "", [], { channel_url: url_id })), "ChannelPage");
          }
          break;
        default:
          break;
      }
    }
    // open player page (fullscreen)
    else if (page == "player") {
      if (type === "channel") {
        Plugin.getInstance()
          .fetchCurrentDirect(url_id)
          .toPromise()
          .then(item => {
            if (item instanceof Unit || item instanceof Extrait || item instanceof Integrale || item instanceof Flux) {
              Config.openedPlayerFromDeeplink = true;
              pushPlayerPage(item);
            } else {
              Log.app.error("Deeplink fetchCurrentDirect: unhandled video item", item);
            }
          })
          .catch(e => {
            Log.app.error("FetchCurrentDirect error :", e);
          });
      } else {
        const unit = new Unit(
          url_id,
          "",
          "",
          "",
          [],
          new Metadata({}, 0),
          {},
          new Media({}, null, null, null),
          true,
          false
        );
        Plugin.getInstance()
          .fetchDetailed(unit)
          .subscribe(
            value => {
              // Here use it to create the UI
              //Log.api.log("[DEEPLINK TO PLAYER] Next !", value);
              Config.openedPlayerFromDeeplink = true;
              const newUnit = value[0];
              if (newUnit !== undefined) {
                pushPlayerPage(
                  new Unit(
                    newUnit.id,
                    newUnit.type,
                    newUnit.title,
                    newUnit.summary,
                    newUnit.artworks,
                    newUnit.metadata,
                    newUnit.extras,
                    newUnit.media,
                    newUnit.login,
                    newUnit.sponsored
                  )
                );
              }
            },
            error => {
              // Here use it to trigger and display an error
              Log.api.error("[DEEPLINK TO PLAYER] Error !", error);
            },
            () => {}
          );
      }
    }
  }
}

/** from RSS data, need to be provided as below, where $CONTENTID is the format deeplink
 * {
    "id": "com.yourdomain.app.appname",
    ...
    "deeplinkingParams" : "{ \"contentTarget\": \"$CONTENTID\" }"
}
 */
function deepLinkLG() {
  Log.app.log("[deepLinkLG] ????? ");

  //Get launchParams when your app is launched.
  window.webOSDev = webOSDev;
  const launchParams = webOSDev.launchParams();

  if (launchParams) {
    if (launchParams.contentTarget) {
      // If the contentTarget has a data in Target URL format, use it as a location.
      loadDeepLink(launchParams.contentTarget.toString());
    } else {
      // If contentTarget has no data, handle the exception.
      // At the moment, nothing happens by default
    }
  } else {
    // If your app do not support deep linking, handle the exception.
    // At the moment, nothing happens by default
  }
}

/**
 * When a tile is clicked, its associated "action_data" value is converted to an ApplicationControlData
 * object with the "PAYLOAD" key
 */
function deepLinkTizen() {
  Log.app.log("[deepLinkTizen] ????? ");

  const requestedAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();

  if (requestedAppControl && requestedAppControl.appControl && requestedAppControl.appControl.data) {
    const payloadData = getPayloadData(requestedAppControl.appControl.data);

    Log.app.log("[deepLinkTizen] requestedAppControl: " + JSON.stringify(requestedAppControl));

    if (payloadData) {
      Log.app.log("[AppControl] PAYLOAD object found - app launched from preview.");
      Log.app.log("[deepLinkTizen] appControlData deeplink : " + payloadData.contentId.toString());
      loadDeepLink(payloadData.contentId.toString());
    } else {
      Log.app.log("[AppControl] No PAYLOAD object found - app launched normally.");
    }
  }
}

function deepLinkOrange() {
  const deeplink = document.location.href.split("#")[1];
  Log.app.log("[deepLinkOrange] hash params ", deeplink);
  if (deeplink !== undefined) {
    loadDeepLink(`#${deeplink}`);
    return;
  } else {
    const orangeUrlParams: {
      universe?: string;
      providerContentId?: string;
      videoId?: string;
      action?: "detail" | "play";
    } = {};

    const locationSearchString = window.location.search;
    Log.app.log("[deepLinkOrange] locationSearchString ", locationSearchString);
    try {
      decodeURI(window.location.search)
        .replace("?", "")
        .split("&")
        .forEach(param => {
          const [key, value] = param.split("=");
          if (value !== undefined && (key === "universe" || key === "providerContentId" || key === "videoId")) {
            orangeUrlParams[key] = value;
          } else if (key === "action") {
            if (value === "detail" || value === "play") {
              orangeUrlParams[key] = value;
            }
          }
        });
    } catch (error) {
      Log.app.log("[deepLinkOrange] failed decoding location.search", window.location.search, error);
    }
    Log.app.log("[deepLinkOrange] orangeUrlParams ", orangeUrlParams);
    if (orangeUrlParams.universe !== undefined) {
      if (orangeUrlParams.action === "detail" || orangeUrlParams.action === undefined) {
        loadDeepLink("#page/channel/" + orangeUrlParams.universe);
        return;
      }
      if (orangeUrlParams.action === "play") {
        loadDeepLink("#player/channel/" + orangeUrlParams.universe);
        return;
      }
      Log.app.warn(
        "[deepLinkOrange] orangeUrlParams unknown action for universe",
        orangeUrlParams.action,
        orangeUrlParams
      );
      return;
    }

    //specifique behaviour to handle videoId deeplink param (same behaiour as providerContentId)
    if (orangeUrlParams.providerContentId === undefined && orangeUrlParams.videoId) {
      orangeUrlParams.providerContentId = orangeUrlParams.videoId;
    }

    if (orangeUrlParams.providerContentId) {
      if (orangeUrlParams.action === "detail") {
        loadDeepLink("#page/unit/" + orangeUrlParams.providerContentId);
        return;
      }
      if (orangeUrlParams.action === "play") {
        loadDeepLink("#player//" + orangeUrlParams.providerContentId);
        return;
      }
      Log.app.warn(
        "[deepLinkOrange] orangeUrlParams unknown action for providerContentId",
        orangeUrlParams.providerContentId,
        orangeUrlParams
      );
      return;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPayloadData(data: any) {
  return data.filter(isPayload).map(decodeData)[0];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPayload(data: any) {
  return data.key === "PAYLOAD";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function decodeData(data: any) {
  const values = JSON.parse(data.value[0]).values;
  const decodedValues = decodeURIComponent(values);

  return JSON.parse(decodedValues);
}

export function exitApp(): boolean {
  Log.app.log("QUIT THE APP");
  navigationStack.pushPage(
    new PopupPage({
      title: "Souhaitez-vous quitter l'appli ?",
      description: "",
      button1: {
        label: "oui",
        action: () => {
          sendOrangeEvent({
            eventType: "exit",
            category: "exit_popup",
            providerId: DEFAULT_ORANGE_PROVIDER_ID,
            label: "Souhaitez-vous quitter l'appli ?",
          });
          platform.exit();
        },
      },
      button2: {
        label: "non",
      },
    })
  );

  return false;
}

window.onload = startApp;
