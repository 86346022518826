import "./playlistMixedView.scss";

import { parseDaysLeft } from "~tools/convert";
import { getChannelPicto } from "~tools/getChannelPics";
import { DOMHelper, View } from "~ui-lib";

import { checkImage } from "../tools/tools";

export class PlaylistMixedView extends View {
  item: any;
  tag?: HTMLElement;

  constructor(item: any) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemMixedVignette"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemMixedViewBoxZoom");
    this.item = item;
    const elementImg = DOMHelper.createDivImg(
      box,
      null,
      "elementImg",
      require("~images/fallbacks/fallback-vignette.png")
    );
    const isPLayable = this.item.type == "unitaire" || this.item.type == "integrale" || this.item.type == "extrait";
    const titleElt = DOMHelper.createDivWithParent(elementImg, null, "titleMixedFallback", item.title ?? "");

    const backgroundSrc = this.item.getLandscapeTileImgUrl();
    if (backgroundSrc.length) {
      checkImage(
        backgroundSrc,
        () => {
          elementImg.removeChild(titleElt);
          elementImg.style.backgroundImage = `url("${backgroundSrc}")`;
        },
        () => {}
      );
    }

    if (isPLayable) {
      const playDisplay = DOMHelper.createDivImg(
        elementImg,
        null,
        "playIconItem",
        require("~images/pictos_options/play.png")
      );
      if (this.item.metadata && this.item.metadata.extras && this.item.metadata.extras.is_live)
        playDisplay.style.display = "none";
      if (item.media) {
        const availability = parseDaysLeft(item.media.end_date);
        if (availability != "") {
          this.tag = DOMHelper.createDivWithParent(elementImg, null, "daysLeftBox");
          DOMHelper.createDivWithParent(this.tag, null, "daysLeft", "plus que " + availability);
        }
      }
    }
    if (this.item.metadata && this.item.metadata.rating && this.item.metadata.rating.code) {
      const picto = this.item.metadata.rating.picto(this.item.metadata.rating.code);
      if (picto) DOMHelper.createDivImg(elementImg, null, "pictosCsa", picto);
    }

    const channelPicto = getChannelPicto(item, true);
    if (channelPicto) {
      DOMHelper.createDivImg(elementImg, null, "infoChannelIcon", channelPicto);
    }

    !this.tag &&
      item.sponsored &&
      DOMHelper.createSpanWithParent(elementImg, null, "sponsoredTag tile_16_9", "sponsorisé");
  }

  onFocused = () => {};
}
