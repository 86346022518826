import { DOMHelper, IFocusable, View } from "~ui-lib";

import { AgePage } from "./agePage";

export type ItemImages = {
  iconFocus: string | null;
  iconUnfocus: string | null;
};

export type ItemAge = {
  id: string;
  label: string;
  age: number;
  link: string;
  slug: string;
  sound: string;
  background: string;
  type: string;
};

export class AgeTileView extends View implements IFocusable {
  private images: ItemImages;
  private itemAge: number;
  private divImage: HTMLDivElement;
  private agePage: AgePage;
  constructor(agePage: AgePage, item: ItemAge) {
    super(DOMHelper.createDivWithParent(null, null, "ageBox"));
    this.agePage = agePage;
    const images: ItemImages = { iconFocus: null, iconUnfocus: null };

    try {
      images.iconFocus = require(`~images/pictos_ages/ageFocus/${item.slug}.png`);
      images.iconUnfocus = require(`~images/pictos_ages/age/${item.slug}.png`);
    } catch (error) {
      console.error("[itemMenu] Error !", error);
    }
    this.images = images;
    this.itemAge = item.age;

    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ageBoxZoom");
    this.divImage = DOMHelper.createDivImg(box, null, "ageBoxBackground", images.iconUnfocus);
  }

  onFocused() {
    this.divImage.style.background = `url(${this.images.iconFocus})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }

  onUnfocused() {
    this.divImage.style.background = `url(${this.images.iconUnfocus})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }
}
