import "./mainMenu.scss";

import { CategoriesTab } from "~pages/category/categoriesTab";
import { HomeTab } from "~pages/home/homeTab";
import { LivesTab } from "~pages/lives/livesTab";
import { isKidPage, pushTabWithMenu } from "~pages/rootPage";
import { SearchTab } from "~pages/search/searchTab";
import {
  createListComponent,
  DOMHelper,
  IListComponent,
  IPage,
  platform,
  PlatformType,
  StaticModelSource,
  View,
} from "~ui-lib";

import { Config } from "../../config";
import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { Event } from "../../models/event";
import { AccountTab } from "../../pages/account/accountTab";
import { EventTab } from "../../pages/event/eventTab";
import { FavoritesTab } from "../../pages/favorites/favoritesTab";
import { OkooTab } from "../../pages/okoo/okooTab";
import { AgePage } from "../../pages/okooAgePage/agePage";
import { ParentalControlPopup } from "../../pages/parentalControlPopup/parentalControlPopup";
import { sendOrangeClickMenuEvent, sendOrangeEvent } from "../../tools/analytics/orangeStats";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { DEFAULT_ORANGE_PROVIDER_ID } from "../../tools/apiOrange/orangeProviderId";
import { ItemMenu, MainMenuItemSlug } from "./itemMenu";
import { ItemMenuView } from "./itemMenuView";

export const idSearchPage = "searchPage";

class WipTab extends View {
  constructor(htmlEltId: string, message: string) {
    super(DOMHelper.createDivWithParent(null, htmlEltId, "wip", message));
  }
}

class WipPage extends View implements IPage {
  constructor(htmlEltId: string, message: string) {
    super(DOMHelper.createDivWithParent(null, htmlEltId, "wip focused", message));
  }

  //   // TODO: will be useless with newer version of ui-lib
  //   onNav = (key: Keys): boolean => {
  //     switch (key) {
  //       case Keys.back:
  //         navigationStack.removePage(this);
  //         return true;
  //     }
  //     return false;
  //   };
}

export class MainMenuComponent extends View {
  private static _instance: MainMenuComponent;

  private _list?: IListComponent<ItemMenu>;
  private _itemSelected?: ItemMenuView = undefined;
  private _slugsIds: { slug: string; modelSourceId: string }[] = [];
  private _modelSource?: StaticModelSource<ItemMenu>;

  public static getInstance(): MainMenuComponent {
    if (!MainMenuComponent._instance) {
      MainMenuComponent._instance = new MainMenuComponent();
      MainMenuComponent._instance.cacheable = true;
    }

    return MainMenuComponent._instance;
  }

  private constructor() {
    super(DOMHelper.createDivWithParent(null, "MainMenu", "mainMenu"));
    this._fetchSource();
  }

  private _fetchSource = () => {
    Plugin.getInstance()
      .fetchMenu()
      .subscribe(
        (menuItems: ItemMenu[]) => {
          // Here use it to create the UI
          !Config.enabledFeatures.live && (menuItems = menuItems.filter(item => item.slug !== "directs"));
          platform.type === PlatformType.orange &&
            menuItems.push({
              id: "replay_orange",
              label: "replay d'Orange",
              order: 7,
              link: "",
              slug: MainMenuItemSlug.replayOrange,
              visible: true,
            });

          this._createList(menuItems.filter(item => item.visible));
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[MENU] Error !", error);
        },
        () => {
          console.log("[MENU] Complete !");
        }
      );
  };

  private _createList(source: ItemMenu[]) {
    this.rootElement.style.paddingTop = (1080 / 2 - ((58 + 26) / 2) * source.length + 26 / 2).toString() + "px"; // padding-top = (heightMenu / 2) - ((heightItem + margin) / 2 * xItems)
    this._modelSource = new StaticModelSource(source);
    this.delegate = this._list = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: this._modelSource,
        viewFactory: model => {
          this._slugsIds.push({ slug: model.slug, modelSourceId: model.id + "" });
          return new ItemMenuView(model);
        },
        horizontal: false,
        pageSize: source.length,
        spatialFocus: false,
        onSelect: this._onItemSelect,
      },
      list => {
        this._list = list;
        this.selectMenuItemSlug(MainMenuItemSlug.home);
      }
    );
  }

  private _pushToTab = (item: ItemMenu) => {
    const slug: MainMenuItemSlug = item.slug;
    this._selectMenuItem(item.id);
    switch (slug) {
      case MainMenuItemSlug.search:
        pushTabWithMenu(new SearchTab(), "searchPage", slug);
        return;
      case MainMenuItemSlug.home:
        pushTabWithMenu(new HomeTab(), "homePage", slug);
        return;
      case MainMenuItemSlug.lives:
        pushTabWithMenu(new LivesTab(), "LivesPage", slug);
        return;
      case MainMenuItemSlug.favorites:
        pushTabWithMenu(new FavoritesTab(), "FavoritesPage", slug);
        return;
      case MainMenuItemSlug.accountSettings:
        pushTabWithMenu(new AccountTab(), "AccountPage", slug);
        return;
      case MainMenuItemSlug.categories:
        pushTabWithMenu(new CategoriesTab(), "CategoryPage", slug);
        return;
      case MainMenuItemSlug.kids:
        pushTabWithMenu(
          new AgePage(true, () => {
            pushTabWithMenu(new OkooTab(), "OkooPage");
          }),
          "OkooAgePage",
          slug
        );
        return;
      case MainMenuItemSlug.custom:
        pushTabWithMenu(
          new EventTab(new Event("", "", "", "", [], { url_complete: item.link }, false)),
          "EventPage",
          slug
        );
        return;
      default:
        return;
    }
  };

  private _sendItemMenuClickAnalytic = (item: ItemMenu) => {
    const slug: MainMenuItemSlug = item.slug;
    sendOrangeClickMenuEvent({ label: item.label });
    switch (slug) {
      case MainMenuItemSlug.search:
        sendPianoAnalytic("click.action", {}, { click: "recherche", zone: "menu", feature: "menu_recherche" });
        return;
      case MainMenuItemSlug.home:
        sendPianoAnalytic("click.action", {}, { click: "accueil", zone: "menu", feature: "menu_accueil" });
        return;
      case MainMenuItemSlug.lives:
        sendPianoAnalytic("click.action", {}, { click: "directs", zone: "menu", feature: "menu_directs" });
        return;
      case MainMenuItemSlug.favorites:
        sendPianoAnalytic("click.action", {}, { click: "mes_videos", zone: "menu", feature: "menu_mes_videos" });
        return;
      case MainMenuItemSlug.accountSettings:
        sendPianoAnalytic("click.action", {}, { click: "mon_compte", zone: "menu", feature: "menu_mon_compte" });
        return;
      case MainMenuItemSlug.categories:
        sendPianoAnalytic("click.action", {}, { click: "categories", zone: "menu", feature: "menu_categories" });
        return;
      case MainMenuItemSlug.kids:
        sendPianoAnalytic("click.action", {}, { click: "enfants", zone: "menu", feature: "menu_enfants" });
        return;
      case MainMenuItemSlug.custom:
        sendPianoAnalytic(
          "click.action",
          {},
          {
            click: "icone",
            zone: "menu",
            feature: "icone_evenement_menu_lateral",
            highlight: "les_jeux_olympiques_2024",
          }
        );
        return;
      case MainMenuItemSlug.replayOrange:
        // sendPianoAnalytic("click.action", {}, { click: "replay", zone: "menu", feature: "menu_replay_orange" });
        return;
      default:
        return;
    }
  };

  private _isKidPageInNavigationStack(): boolean {
    for (const page of navigationStack.pages$.value) {
      if (isKidPage(page) || page instanceof OkooTab) {
        return true;
      }
    }
    return false;
  }

  private _onItemSelect = (item: ItemMenu): boolean => {
    this._sendItemMenuClickAnalytic(item);
    if (item.slug === MainMenuItemSlug.replayOrange) {
      this._openReplayOrange();
      return true;
    }
    // if a "kid page" is in the stack we need to display the parentalControlPopup
    // unless the user selected the menu entry "kids"
    if (item.slug !== MainMenuItemSlug.kids && this._isKidPageInNavigationStack()) {
      navigationStack.pushPage(
        new ParentalControlPopup(() => {
          this._pushToTab(item);
        })
      );
    } else {
      this._pushToTab(item);
    }
    return true;
  };

  private _selectMenuItem = (id: string) => {
    const itemSelected = this._list?.viewFromId(id) as ItemMenuView | undefined;
    this._itemSelected?.unSelect();
    this._itemSelected = itemSelected;
    itemSelected?.select();
    this._list?.setFocusOnId(id);
  };

  private _idFromSlug = (slug: MainMenuItemSlug) => {
    return this._slugsIds.find(item => item.slug === slug)?.modelSourceId;
  };

  selectMenuItemSlug = (slug: MainMenuItemSlug) => {
    const id = this._idFromSlug(slug);
    id && this._selectMenuItem(id);
  };

  private _openReplayOrange = () => {
    if (platform.type === PlatformType.orange) {
      sendOrangeEvent({
        eventType: "exit",
        category: "menu",
        providerId: DEFAULT_ORANGE_PROVIDER_ID,
        label: "replay d'Orange",
      });
      (window as any).close();
    } else {
      Log.app.error("Cannot open replay d'Orange on foreign device");
    }
  };
}
