import { Collection } from "./collection";
import { Event } from "./event";
import { Extrait } from "./extrait";
import { Integrale } from "./integrale";
import { Program } from "./program";
import { Unit } from "./unit";

export class MEABanner {
  readonly id: string;
  readonly type = "mea_banner";
  readonly item: Integrale | Program | Unit | Collection | Event | Extrait;
  constructor(item: Integrale | Program | Unit | Collection | Event | Extrait) {
    this.item = item;
    this.id = item.id;
  }
}
