import "./agePage.scss";

import { Storage } from "~libs/storage";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { createListComponent, DOMHelper, IListComponent, IPage, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { setPianoAge } from "../../tools/analytics/piano";
import { OkooTab } from "../okoo/okooTab";
import { ParentalControlPopup } from "../parentalControlPopup/parentalControlPopup";
import { pushTabWithMenu } from "../rootPage";
import { AgeTileView, ItemAge } from "./itemAge";

export class AgePage extends View implements IPage {
  public static selectAge: ItemAge | undefined = undefined;
  listComponent?: IListComponent<ItemAge>;
  private _background?: DynamicBackground;
  title: string;
  subTitle: string;
  private _isFirstPage: boolean;
  private _logo: HTMLElement | undefined;
  private _onSelectAge: (itemAge: ItemAge) => void;

  constructor(isFirstPage: boolean, onSelectAge: (itemAge: ItemAge) => void) {
    super(DOMHelper.createDivWithParent(null, "AgePage"));
    this._onSelectAge = onSelectAge;
    this._isFirstPage = isFirstPage;
    if (isFirstPage) {
      this.title = "Choisir un âge";
      this.subTitle = `Le contenu proposé est automatiquement adapté en fonction de l'âge sélectionné.`;
    } else {
      this.title = "Changer d'âge";
      this.subTitle = `Modifier l'âge permet de découvrir d'autres contenus.`;
    }
    this._fetchSource();
  }

  private _fetchSource = () => {
    Plugin.getInstance()
      .fetchAge()
      .subscribe(
        (items: ItemAge[]) => {
          // Here use it to create the UI
          this._onASourceReady(items);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[AGE] Error !", error);
        },
        () => {
          Log.api.info("[AGE] Complete !");
        }
      );
  };

  private _onASourceReady(source: ItemAge[]) {
    this._background = new DynamicBackground(this.rootElement, { overlay: DynamicBackgroundOverlayType.none });
    DOMHelper.createDivWithParent(this.rootElement, null, "contentAgePageTitle", this.title);
    DOMHelper.createDivWithParent(this.rootElement, null, "contentAgePageSubTitle", this.subTitle);
    this._logo = DOMHelper.createDivImg(
      this.rootElement,
      null,
      "contentAgePageLogo",
      require("~images/pictos_okoo/logo_okoo_age_young.png")
    );

    const listContainer = DOMHelper.createDivWithParent(this.rootElement, null, "listContainer");

    const contentRoot = DOMHelper.createDivWithParent(listContainer, null, "contentAgePageSwimlane");
    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: contentRoot,
        modelSource: new StaticModelSource(source),
        viewFactory: model => {
          return new AgeTileView(this, model);
        },
        arrowFactory: horizontalArrowFactory,
        pageSize: 1,
        visibleAfter: 9,
        visibleBefore: 9,
        horizontal: true,
        onSelect: this.onSelect,
      },
      mainList => {
        const age = Storage.getItem("age");
        if (age != null) {
          const ageValue = Number(age);
          mainList.setFocusOnIndex(ageValue - 3);
        } else {
          mainList.setFocusOnIndex(0);
        }
      }
    );
    this._background?.setAgeCollectionList(this.listComponent);
  }

  onRelease = () => {
    this._background?.onRelease();
  };

  onSelect = (model: ItemAge): boolean => {
    const age = Storage.getItem("age");
    if (age !== null && Number(age) < model.age) {
      navigationStack.pushPage(
        new ParentalControlPopup(() => {
          this._setAgeParameters(model);
          pushTabWithMenu(new OkooTab(), "okooPage");
        })
      );
      return true;
    } else {
      this._setAgeParameters(model);
      this._onSelectAge(model);
    }

    return true;
  };

  private _setAgeParameters = (model: ItemAge) => {
    AgePage.selectAge = model;
    setPianoAge(model.age);
    OkooTab._currentAge = model;
    Storage.setItem("age", model.age.toString());
  };
}
