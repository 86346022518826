import "./playlistVideoView.scss";

import { Plugin } from "~datas/plugin";
import { convertDateDayMonth, parseDaysLeft } from "~tools/convert";
import { getChannelPicto } from "~tools/getChannelPics";
import { checkImage } from "~tools/tools";
import { DOMHelper, Keys, View } from "~ui-lib";

import { PlayHistoryHelper } from "../tools/playerHistoryHelper";

export class PlaylistVideoView extends View {
  private elementImg?: HTMLElement;
  private item: any;
  private isPlaying: boolean;
  private showMeta: boolean;
  private tag?: HTMLElement;
  private _progressPercent?: number;
  private _playIconElement?: HTMLDivElement;
  private _progressBarView?: ProgressBarView;

  constructor(item: any, showMeta = false, isPlaying = false) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemVideoVignette"));
    this.isPlaying = isPlaying;
    this.item = item;
    this.showMeta = showMeta;
    if (this.showMeta) DOMHelper.addClass(this.rootElement, "showMeta");
    this._createVideoView();
    this._fetchResume();
  }

  onShown() {
    this._fetchResume();
  }

  private _fetchResume() {
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      const duration = Math.max(0, this.item.metadata.duration);
      const offset = Math.max(0, Math.min(duration, PlayHistoryHelper.getCurrentOffset(this.item) ?? 0));
      const progressPercent = duration <= 0 ? 0 : offset / duration;

      if (this._playIconElement !== undefined) {
        this._playIconElement.style.backgroundImage =
          "url('" +
          (progressPercent > 0.96
            ? require("~images/pictos_options/replay.png")
            : require("~images/pictos_options/play.png")) +
          "')";
      }

      this._progressPercent = progressPercent;
      this._updateProgressBar(progressPercent);
    }
  }

  private _createVideoView = () => {
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemVideoViewBoxZoom");

    this.elementImg = DOMHelper.createDivImg(
      box,
      null,
      "elementImg",
      require("~images/fallbacks/fallback-vignette.png")
    );
    const titleElt = DOMHelper.createDivWithParent(this.elementImg, null, "titleFallback", this.item.title ?? "");

    const imgUrl = this.item.getLandscapeTileImgUrl();
    if (imgUrl.length) {
      checkImage(
        imgUrl,
        () => {
          if (this.elementImg !== undefined) {
            this.elementImg.removeChild(titleElt);
            this.elementImg.style.background = `url("${imgUrl}") no-repeat`;
            this.elementImg.style.backgroundSize = "100%";
          }
        },
        () => {}
      );
    }

    if (this.item.type == "unitaire" || this.item.type == "integrale" || this.item.type == "extrait") {
      this._playIconElement = DOMHelper.createDivImg(
        this.elementImg,
        null,
        "playIconItem",
        require("~images/pictos_options/play.png")
      );
      if (this.item.metadata && this.item.metadata.extras && this.item.metadata.extras.is_live) {
        this._playIconElement.style.display = "none";
      }
      if (this.item.media) {
        const availability = parseDaysLeft(this.item.media.end_date);
        if (availability != "") {
          this.tag = DOMHelper.createDivWithParent(this.elementImg, null, "daysLeftBox");
          DOMHelper.createDivWithParent(this.tag, null, "daysLeft", "plus que " + availability);
        }
      }
    }
    if (this.item.metadata && this.item.metadata.rating && this.item.metadata.rating.code) {
      const picto = this.item.metadata.rating.picto(this.item.metadata.rating.code);
      if (picto) DOMHelper.createDivImg(this.elementImg, null, "pictosCsa", picto);
    }
    const channelPicto = getChannelPicto(this.item, true);
    if (channelPicto) {
      DOMHelper.createDivImg(this.elementImg, null, "infoChannelIcon", channelPicto);
    }

    !this.tag &&
      this.item.sponsored &&
      DOMHelper.createSpanWithParent(this.elementImg, null, "sponsoredTag tile_16_9", "sponsorisé");

    if (this.showMeta) {
      let title = "";
      let subtitle = "";
      if (this.item.extras?.event) {
        // if video is link to a event
        title = this.item.extras.event.title || "";
        subtitle = this.item.title || "";
      } else if (this.item.extras?.program) {
        // if video is link to a program
        title = this.item.extras.program.title || "";
        subtitle = this.item.extras.episode_title || "";
      } else {
        title = this.item.title || "";
        subtitle = "";
      }
      DOMHelper.createDivWithParent(box, null, "metadataTitle", title);
      DOMHelper.createDivWithParent(box, null, "metadataSubtitle", subtitle);

      let diffusion = "";
      if (this.item.media?.broadcast?.start_date) {
        diffusion = "diffusé le " + convertDateDayMonth(this.item.media?.broadcast?.start_date);
      }
      if (this.item.metadata?.duration) {
        diffusion =
          diffusion +
          (diffusion !== "" ? "\u00a0\u00a0\u00a0|\u00a0\u00a0\u00a0" : "") +
          Math.ceil(this.item.metadata.duration / 60) +
          "mn";
      }
      DOMHelper.createDivWithParent(box, null, "metadataDiffusion", diffusion);
    }
    if (this.isPlaying) {
      const mask = DOMHelper.createDivWithParent(this.elementImg, null, "elementImgMask");
      DOMHelper.createSpanWithParent(mask, null, "maskText", "en cours de lecture");
    }
  };

  private _updateProgressBar = (percent: number) => {
    if (percent <= 0) {
      this._progressBarView?.rootElement.remove();
      this._progressBarView = undefined;
      return;
    }
    if (this._progressBarView === undefined) {
      this._progressBarView = new ProgressBarView();
      this.elementImg?.appendChild(this._progressBarView.rootElement);
    }
    this._progressBarView.setPercent(percent);
  };

  getProgressPercent = (): number | undefined => {
    return this._progressPercent;
  };

  onFocused = () => {};

  onNav = (key: Keys): boolean => {
    switch (key) {
      case Keys.select:
        if (this.isPlaying) return true;
        return false;
      default:
        return false;
    }
  };
}

class ProgressBarView extends View {
  private _progressBarPercentElement;
  constructor() {
    super(DOMHelper.createDivWithParent(null, null, "progressBar"));
    this._progressBarPercentElement = DOMHelper.createDivWithParent(this.rootElement, null, "progressBarPercent");
    DOMHelper.createDivWithParent(this._progressBarPercentElement, null, "round");
  }
  setPercent(percent: number) {
    this._progressBarPercentElement.style.width = `${Math.min(percent * 100, 100)}%`;
  }
}
